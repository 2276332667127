.overlay-div {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
}
.overlay-spinner {
  width: 150px;
}
